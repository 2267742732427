import type { Season } from '@charterindex/armoury-common';
import type { Translate } from 'next-translate';
import type { Language, YachtRatesSlice } from '../../domain';
import type { SliceProps } from './SliceProps';
import { getUpcomingSeasons } from '@charterindex/armoury-common';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { css, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useTranslation from 'next-translate/useTranslation';
import { InternalLink, TextOverImage } from '../components';
import { formatCurrency } from '../preferences';
import { getImageUrl } from '../util';
import { SliceTitle } from './components';


const CHIP_IMG_SIZE = 38;
const SubAreaChipRoot = styled(Paper)(({ theme }) => css`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${theme.spacing(1)};
	
	border-radius: ${CHIP_IMG_SIZE}px;
	padding: ${theme.spacing(.5)};
	padding-right: ${theme.spacing(1.5)};
	
	&:hover {
		background-color: ${theme.palette.grey[100]};
	}
	
	transition: ${theme.transitions.create('background-color', {
		duration: theme.transitions.duration.standard,
	})};
	
	img {
		display: block;
		border-radius: 50%;
		object-fit: cover;
		width: ${CHIP_IMG_SIZE}px;
		height: ${CHIP_IMG_SIZE}px;
	}
`);
const SubAreaChip = (p: { subArea: YachtRatesSlice.SubArea }) => {
	return (
		<InternalLink href={p.subArea.uri}>
			<SubAreaChipRoot variant="outlined">
				<div className="imgWrapper">
					<img src={getImageUrl(p.subArea.image, CHIP_IMG_SIZE * 3)} alt=""/>
				</div>
				<Typography variant="body2">{p.subArea.name}</Typography>
			</SubAreaChipRoot>
		</InternalLink>
	);
};

const getRateLabel = (t: Translate, area: YachtRatesSlice.Area, rate: YachtRatesSlice.Rate): string | null => {
	if(rate.rateType === 'only') {
		return null;
	}
	if(!area.season) {
		return t(`common:${rate.rateType}Rate`) + ' ';
	}

	return t(`common:${rate.rateType}SeasonalRate`) + ' ';
};

const AreaRoot = styled('div')(({ theme }) => css`
	display: flex;
	flex-direction: column;
	gap: ${theme.spacing(1)};
	
	${theme.breakpoints.up('sm')} {
		display: grid;
		
		grid-template-columns: 350px 1fr;
		grid-template-rows: 1fr;

		.image {
			min-height: 300px;
		}
		.cruisingAreas {
			grid-column: 1 / -1;
		}
	}
	
	& + * {
		border-top: 1px solid ${theme.palette.grey[200]};
		padding-top: ${theme.spacing(2)};
	}

	.image {
		position: relative;
		display: flex;
		border-radius: ${theme.shape.borderRadius}px;
		box-shadow: ${theme.shadows[1]};
		overflow: hidden;
		min-height: 200px;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			object-position: center;
			object-fit: cover;
		}
		
		h3 {
			z-index: 1;
			margin: ${theme.spacing(2)};
		}
	}
	
	.rates {
		li {
			display: flex;
			flex-direction: column;
			gap: ${theme.spacing(.5)};
			padding: ${theme.spacing(1)};
			* {
				line-height: 1;
			}
		}
		
		.priceValue {
			font-size: 1.2em;
		}
	}
	
	.cruisingAreas {
		ul {
			display: flex;
			flex-wrap: wrap;
			gap: ${theme.spacing(1)};
		}
	}
	
	h4 {
		font-weight: bolder;
		font-size: 1.15em;
	}
	ul {
		margin: 0;
		padding: 0;
	}
	li {
		list-style: none;
	}
	
`);

const getSeasonTitle = (t: Translate, seasons: Season[], area: YachtRatesSlice.Area): string => {
	const season = seasons.find(season => season.type === area.season);
	if(!season) {
		return '';
	}

	const year = season.type === 'summer'
		? season.startingYear
		: `${season.startingYear} / ${season.startingYear + 1}`;

	return t(`common:season.${season.type}`) + ' ' + year;
};

export const Slice_YachtRates = (p: SliceProps<YachtRatesSlice>): JSX.Element => {
	const { t, lang } = useTranslation();
	const seasons = getUpcomingSeasons(2);

	const areas = [ ...p.slice.areas ].sort((a, b) => {
		if(a.season === b.season) {
			return 0;
		}

		const numA = seasons.findIndex(season => season.type === a.season);
		const numB = seasons.findIndex(season => season.type === b.season);

		return numA - numB;
	});

	return (
		<section>
			<SliceTitle title={p.slice.title}></SliceTitle>
			<Stack gap={2}>
				{ areas.map(area => {
					const subAreaDescriptionId = area.id + 'SubAreaDescription';

					return (
						<AreaRoot key={area.id}>
							<InternalLink className="image" href={area.uri}>
								<img src={getImageUrl(area.image, 700)} alt=""/>
								<TextOverImage variant="h3">
									{ area.name }
								</TextOverImage>
							</InternalLink>
							<div className="rates">
								<Typography component="h4">
									{ getSeasonTitle(t, seasons, area) }
								</Typography>
								<Stack component="ul" gap={1} aria-label="Rates">
									{area.rates.map(rate => (
										<Paper component="li" variant="outlined" key={rate.id}>
											{!rate.amount && t('common:enquire-for-rate')}
											{rate.amount && (
												<>
													<Typography component="span">
														{getRateLabel(t, area, rate)}
													</Typography>
													<div>
														<strong className="priceValue">{formatCurrency(lang as Language, rate.currency, rate.amount)}</strong>
														<Typography variant="body2" component="span">&nbsp;{t('common:per-week')}</Typography>
													</div>
													<Typography variant="body2" component="div">
														{rate.expense.name}
													</Typography>
													{ rate.baseAmount && rate.baseCurrency !== rate.currency && (
														<Typography variant="caption" fontStyle="italic">
															{ t('common:baseCurrency') }&nbsp;
															{ formatCurrency(lang as Language, rate.baseCurrency, rate.baseAmount) }
														</Typography>
													) }
												</>
											)}
										</Paper>
									))}
								</Stack>
							</div>
							<div className="cruisingAreas">
								<Typography component="h4" id={subAreaDescriptionId}>
									{t('common:cruising-areas')}
								</Typography>
								<ul aria-describedby={subAreaDescriptionId}>
									{area.subAreas.map(subArea => (
										<SubAreaChip subArea={subArea} key={subArea.id} />
									))}
								</ul>
							</div>
						</AreaRoot>
					);
				})}
			</Stack>
		</section>
	);
};
