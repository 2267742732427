import type { YachtTitleSlice } from '../../domain';
import type { SliceProps } from './SliceProps';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { useYacht } from '../yacht';


const YachtTitleTypography = styled(Typography)`
	&.MuiTypography-h1 {
		font-size: 2.4rem;
	}
	text-transform: capitalize;
`;

export const Slice_YachtTitle = (p: SliceProps<YachtTitleSlice>) => {
	const { t } = useTranslation();
	const yacht = useYacht();

	return (
		<YachtTitleTypography variant="h1">
			{ p.slice.title
				? p.slice.title
				: t(`common:yacht-title.${yacht.active ? 'active' : 'private'}`, { yachtName: yacht.name })
			}
		</YachtTitleTypography>
	);
};
