import type { NextSeoProps } from 'next-seo';
import type { UriData } from '../../domain';
import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';
import { asLanguage } from '../../domain';
import { getImageUrl } from '../util';
import { getAbsoluteUrl } from '../../util';


type LanguageAlternate = { hrefLang: string, href: string };

export const useUriPageSeo = (page: UriData | null): NextSeoProps => {
	const { lang } = useTranslation();

	return useMemo<NextSeoProps>(() => {
		if(!page?.data) {
			return {};
		}

		const currentLang = asLanguage(lang);

		const title = page.type === 'yacht' ? page.data.name : (page.data.metaTitle + ' — Charter Index');
		let canonical: string | undefined;
		const languageAlternates: LanguageAlternate[] = Object.entries(page.data.uris)
			.map(([ hrefLang, uri ]) => {
				const href = getAbsoluteUrl(uri);
				if(currentLang === hrefLang) {
					canonical = href;
				}

				return { hrefLang, href };
			});

		return {
			title,
			description: page.data.metaDescription,
			canonical,
			languageAlternates,
			openGraph: {
				title,
				url: page.data.uris[currentLang],
				description: page.data.metaDescription,
				// TODO: metaImages?
				images: page.data.image ? [{
					url: getImageUrl(page.data.image, 1200),
					width: 1200,
					height: 630,
				}] : [],
			},
		};
	}, [lang, page]);
};
